export const hatAndShirtColors = {
  Black: "#262E33",
  Blue01: "#65C9FF",
  Blue02: "#5199E4",
  Blue03: "#25557C",
  Gray01: "#E6E6E6",
  Gray02: "#929598",
  Heather: "#3C4F5C",
  PastelBlue: "#B1E2FF",
  PastelGreen: "#A7FFC4",
  PastelOrange: "#FFBC69",
  PastelRed: "#FFAFB9",
  PastelYellow: "#FFFFB1",
  Pink: "#FF488E",
  Red: "#FF5C5C",
  White: "#FFFFFF",
};

export const hairColors = {
  Auburn: "#A55728",
  Black: "#2C1B18",
  Blonde: "#B58143",
  BlondeGolden: "#D6B370",
  Brown: "#724133",
  BrownDark: "#4A312C",
  PastelPink: "#F59797",
  Platinum: "#ECDCBF",
  Red: "#C93305",
  SilverGray: "#E8E1E1",
};

export const skinColors = {
  Tanned: "#FD9841",
  Yellow: "#F8D25C",
  Pale: "#FFDBB4",
  Light: "#EDB98A",
  Brown: "#D08B5B",
  DarkBrown: "#AE5D29",
  Black: "#614335",
};

export const circleColors = {
  Crimson: "#e6194b",
  ChateauGreen: "#3cb44b",
  Broom: "#ffe119",
  RoyalBlue: "#4363d8",
  Jaffa: "#f58231",
  Seance: "#911eb4",
  TurquoiseBlue: "#46f0f0",
  RazzleDazzleRose: "#f032e6",
  LasPalmas: "#bcf60c",
  ApricotPeach: "#fabebe",
  Teal: "#008080",
  Mauve: "#e6beff",
  Kumera: "#9a6324",
  LemonChiffon: "#fffac8",
  Maroon: "#800000",
  MintGreen: "#aaffc3",
  Olive: "#808000",
  Caramel: "#ffd8b1",
  NavyBlue: "#000075",
  Gray: "#808080",
  White: "#ffffff",
  Black: "#000000",
};
